@use '~/src/lib/scss/index' as u;

.Bracket {
	.Tree {
		display: flex;
		gap: calc(0.08 * var(--app-width));
		margin: var(--size-gap-4x) 0;
		padding: 0 var(--size-gap-2x);
		width: 100%;
		max-width: var(--app-width);
		overflow-x: auto;
		scrollbar-width: none;

		@include u.desktop {
			gap: var(--size-gap-4x);
			scroll-snap-type: none;
		}
		> div {
			display: grid;
			grid-template-rows: auto;
			grid-template-columns: 1fr;
			grid-auto-rows: auto;
			flex-shrink: 0;
			gap: var(--size-gap-4x);
			width: calc(0.7 * var(--app-width));
			scroll-snap-align: center;
			@include u.desktop {
				width: 280px;
			}
			&.Left > :after,
			&.Right > :after {
				display: block;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				border: 1px solid var(--color-brand-primary);
				width: 8px;
				height: 50%;
				content: '';
			}
			&.Left > :before,
			&.Right > :before {
				display: block;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				background-color: var(--color-brand-primary);
				width: 8px;
				height: 1px;
				content: '';
			}

			&.Left > :after {
				right: calc(-1 * var(--size-gap-2x));
				border-left: none;
				border-right-width: 1px;
			}
			&.Right > :after {
				left: calc(-1 * var(--size-gap-2x));
				border-right: none;
				border-left-width: 1px;
			}
			&.Left > :before {
				right: calc(-1 * var(--size-gap-2x) - 8px);
			}
			&.Right > :before {
				left: calc(-1 * var(--size-gap-2x) - 8px);
			}
			&.Left > div:has(.IndexGame:nth-child(1):last-child):after,
			&.Right > div:has(.IndexGame:nth-child(1):last-child)::after {
				display: none;
			}
			&.Left > div:has(.IndexGame:nth-child(1):last-child):before {
				right: calc(-1 * var(--size-gap-2x) - 4px);
			}

			&.Right > div:has(.IndexGame:nth-child(1):last-child)::before {
				left: calc(-1.5 * var(--size-gap-2x) + 4px);
			}

			> div {
				display: flex;
				flex-shrink: 0;
				flex-direction: column;
				justify-content: space-around;
				gap: var(--size-gap-2x);
			}
		}
	}

	&.Vertical {
		.Tree {
			gap: 0;
			margin: 0;
			padding: 0;

			> div {
				gap: var(--size-gap);
				width: 250px;

				&.Left {
					> div:before {
						right: 0;
					}
					> div:after {
						right: 7px;
					}
				}

				> div {
					gap: var(--size-gap);
				}
			}
		}
	}
}
