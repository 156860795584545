.Button {
	display: block;
	border-radius: 4px;
	background-image: linear-gradient(70deg, var(--color-brand-primary), var(--color-brand-primary));
	background-color: var(--color-brand-primary);
	padding: var(--size-gap-half);
	color: var(--color-white);
	font-family: var(--font-highlight);
	text-align: center;
	&:hover {
		text-decoration: none;
		&:after {
			opacity: 1;
		}
	}

	&:after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: 0;
		mix-blend-mode: overlay;
		transition: 0.2s ease all;
		border-radius: 4px;
		background-image: linear-gradient(120deg, transparent, rgba(0, 0, 0, 0.5));
		content: '';
	}

	> div {
		z-index: 1;
	}
}
