.cookie-consent {
  z-index: 100;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: #000;
  background-color: #ffffffd9;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100px;
  padding: 16px;
  transition: transform .3s ease-in .1s;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
}

.cookie-consent--dark {
  color: #fff;
  background-color: #000000d9;
}

.cookie-consent--dark .cookie-consent__container button {
  color: #fff;
  border: 1px solid #fff;
}

.cookie-consent--visible {
  transition-delay: 1s;
  transform: translateY(0);
}

.cookie-consent__container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  display: flex;
}

.cookie-consent__container svg {
  flex-shrink: 0;
  width: 25px;
  height: 30px;
}

.cookie-consent__container button {
  cursor: pointer;
  background-color: unset;
  color: #000;
  border: 1px solid #000;
  border-radius: 4px;
  width: 100%;
  max-width: 80px;
  padding: 8px;
  font-size: 12px;
  font-weight: 700;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --gap: 16px;
  --gutter: 16px;
}

@media (width >= 768px) {
  :root {
    --gap: 24px;
    --gutter: 24px;
  }
}

@media (width >= 1200px) {
  :root {
    --gap: 24px;
    --gutter: 42px;
  }
}

@media (width <= 768px) {
  .desktop-only {
    display: none !important;
  }
}

@media (width >= 768px) {
  .mobile-only {
    display: none !important;
  }
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*, :before, .after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
  position: relative;
}

body {
  background-color: var(--color-black);
  color: var(--color-white);
  margin: 0;
  padding: 0;
  font-family: Roboto Flex, sans-serif;
  font-stretch: 100%;
  line-height: 1.1;
}

h1, h2, h3, h4, h5 {
  text-transform: uppercase;
  font-family: Orbitron, sans-serif;
  font-weight: 900;
}

h1 {
  font-size: 24px;
}

@media (width >= 768px) {
  h1 {
    font-size: 24px;
  }
}

@media (width >= 1200px) {
  h1 {
    font-size: 36px;
  }
}

h2 {
  font-size: 18px;
}

@media (width >= 768px) {
  h2 {
    font-size: 24px;
  }
}

h3 {
  font-size: 14px;
}

@media (width >= 768px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  color: var(--color-gray-30);
  font-size: 14px;
}

h5 {
  color: var(--color-white);
  font-size: 18px;
  font-weight: 600;
}

@media (width >= 768px) {
  h5 {
    font-size: 24px;
  }
}

p {
  line-height: 1.2;
}

a, a:active, a:visited {
  cursor: pointer;
  color: var(--color-blue-25);
  text-decoration: none;
  transition: all .33s;
}

a:hover {
  opacity: .5;
}

.cta {
  text-align: center;
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 700;
  display: block;
}

.cta.primary {
  background-color: var(--color-otx-blue);
  color: var(--color-white);
  border: 1px solid #0000;
  transition: all .33s;
}

.cta.primary:hover {
  opacity: 1;
  border: 1px solid var(--color-white);
}

.cta.secondary {
  border: 1px solid var(--color-blue-25);
  color: var(--color-white);
  background-color: #0000;
  transition: all .33s;
}

.cta.secondary:hover {
  opacity: 1;
  border: 1px solid var(--color-white);
}

.cta.tertiary {
  border: 1px solid var(--color-white);
  background-color: var(--color-white);
  color: var(--color-black);
  transition: all .33s;
}

.cta.tertiary:hover {
  opacity: 1;
  border: 1px solid var(--color-gray-30);
}

input {
  font-family: Roboto Flex;
}

img {
  display: block;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.Suspended {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.Error {
  justify-content: center;
  align-items: center;
  gap: var(--size-gap-half);
  flex-direction: row;
  font-size: 20px;
  display: flex;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.content-container {
  padding: 0 var(--size-gutter);
  max-width: 1440px;
}

@media (width >= 768px) {
  .content-container {
    margin-inline: auto;
  }
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.footer-legal {
  background-color: var(--color-otx-blue);
}

.footer-legal .content-container {
  color: var(--color-white);
  flex-direction: column;
  gap: 12px;
  padding: 32px 40px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
}

@media (width >= 768px) {
  .footer-legal .content-container {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer-legal .content-container .footer-legal__links a {
  border-right: 1px solid var(--color-gray-40);
  color: var(--color-white);
  padding: 0 8px;
}

.footer-legal .content-container .footer-legal__links a:first-child {
  padding-left: 0;
}

.footer-legal .content-container .footer-legal__links a:last-child {
  border-right: none;
  padding-right: 0;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.footer-navigation {
  background-color: var(--color-otx-blue);
}

.footer-navigation .content-container {
  grid-template-columns: 1fr;
  justify-items: center;
  padding: 0 0 72px;
  display: grid;
}

@media (width >= 768px) {
  .footer-navigation .content-container {
    justify-content: space-between;
    padding: 0;
    display: flex;
  }
}

.footer-navigation .content-container .logo-container {
  padding: 72px 64px;
}

.footer-navigation .content-container .logo-container img {
  box-sizing: content-box;
  justify-self: flex-start;
  max-width: 110px;
  padding: 25px 21px;
}

.footer-navigation .content-container .links {
  flex-direction: row;
  display: flex;
}

@media (width >= 768px) {
  .footer-navigation .content-container .links {
    padding: 96px 0;
  }
}

.footer-navigation .content-container .links .column {
  flex-direction: column;
  padding: 0 24px;
  display: flex;
}

@media (width >= 768px) {
  .footer-navigation .content-container .links .column {
    padding: 0 42px;
  }
}

.footer-navigation .content-container .links .column .column-heading {
  font-size: 18px;
  font-weight: 600;
}

@media (width >= 768px) {
  .footer-navigation .content-container .links .column .column-heading {
    font-size: 24px;
  }
}

.footer-navigation .content-container .links .column a {
  color: var(--color-white);
  padding-top: 16px;
  font-size: 14px;
}

@media (width >= 768px) {
  .footer-navigation .content-container .links .column a {
    font-size: 16px;
  }
}

.footer-navigation .content-container .links .column .social-icons {
  flex-direction: column;
  display: flex;
}

@media (width >= 768px) {
  .footer-navigation .content-container .links .column .social-icons {
    flex-direction: row;
    align-items: center;
  }
}

.footer-navigation .content-container .links .column .social-icons svg {
  position: relative;
}

@media (width >= 768px) {
  .footer-navigation .content-container .links .column .social-icons svg {
    padding: 4px 16px 0 0;
  }
}

.footer-navigation .content-container .links .column .social-icons svg.youtube {
  height: 18px;
}

.footer-navigation .content-container .links .column .social-icons svg.instagram {
  height: 22px;
}

.footer-navigation .content-container .links .column .social-icons svg.tiktok {
  height: 20px;
}

.layout-header {
  z-index: 100;
  background-color: var(--color-black);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: sticky;
  top: 0;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.header-navigation {
  background-color: var(--color-black);
  width: 100%;
  max-width: 1440px;
  position: sticky;
  top: 0;
}

.header-navigation .link {
  color: var(--color-gray-50);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}

.header-navigation .header-navigation__container {
  z-index: 1;
  background-color: var(--color-black);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

@media (width >= 1024px) {
  .header-navigation .header-navigation__container {
    justify-content: flex-start;
  }
}

.header-navigation .header-navigation__container .logo {
  margin: 16px var(--size-gutter);
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 55px;
  display: flex;
}

@media (width >= 1024px) {
  .header-navigation .header-navigation__container .logo {
    padding-right: 0 24px;
    margin: 16px 0 16px 32px;
  }
}

@media (width >= 1456px) {
  .header-navigation .header-navigation__container .logo {
    margin: 16px 0;
  }
}

.header-navigation .header-navigation__container .logo:hover {
  opacity: 1;
  filter: drop-shadow(0 0 8px #fffc);
}

.header-navigation .header-navigation__container .logo img {
  width: 100%;
}

.header-navigation .header-navigation__container .desktop .link {
  border-right: 1px solid var(--color-blue-25);
  padding: 0 32px;
  display: none;
}

@media (width >= 1024px) {
  .header-navigation .header-navigation__container .desktop .link {
    display: unset;
  }
}

.header-navigation .header-navigation__container .desktop .link:last-child {
  border-right: none;
  padding-right: 0;
}

.header-navigation .header-navigation__container .desktop .link:hover {
  opacity: 1;
  color: #fff;
}

.header-navigation .header-navigation__container .toggle {
  padding: 16px var(--size-gutter);
}

.header-navigation .header-navigation__container .toggle .menu-icon svg {
  max-height: 18px;
}

@media (width >= 1024px) {
  .header-navigation .header-navigation__container .toggle {
    display: none;
  }
}

.header-navigation .mobile {
  z-index: -1;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #000000d9;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  transition: all .33s;
  display: flex;
  position: fixed;
  top: -150vh;
  left: 0;
}

.header-navigation .mobile .link {
  margin: 0 var(--size-gap);
  border-bottom: 1px solid var(--color-blue-25);
  padding: var(--size-gap-2x) 0;
}

.header-navigation .mobile .link:last-child {
  border-bottom: none;
}

.header-navigation .mobile--open {
  top: 64px;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@media (width >= 768px) {
  .layout {
    grid-template-rows: auto 1fr auto;
    min-height: 100vh;
    display: grid;
  }
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.not-found-page {
  padding: calc(3 * var(--size-gutter));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.not-found-page > h1 {
  margin: var(--size-gap);
  font-size: 64px;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.Bracket .Tree {
  gap: calc(.08 * var(--app-width));
  margin: var(--size-gap-4x) 0;
  padding: 0 var(--size-gap-2x);
  max-width: var(--app-width);
  scrollbar-width: none;
  width: 100%;
  display: flex;
  overflow-x: auto;
}

@media (width >= 768px) {
  .Bracket .Tree {
    gap: var(--size-gap-4x);
    scroll-snap-type: none;
  }
}

.Bracket .Tree > div {
  gap: var(--size-gap-4x);
  width: calc(.7 * var(--app-width));
  scroll-snap-align: center;
  flex-shrink: 0;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-rows: auto;
  display: grid;
}

@media (width >= 768px) {
  .Bracket .Tree > div {
    width: 280px;
  }
}

.Bracket .Tree > div.Left > :after, .Bracket .Tree > div.Right > :after {
  border: 1px solid var(--color-brand-primary);
  content: "";
  width: 8px;
  height: 50%;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.Bracket .Tree > div.Left > :before, .Bracket .Tree > div.Right > :before {
  background-color: var(--color-brand-primary);
  content: "";
  width: 8px;
  height: 1px;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.Bracket .Tree > div.Left > :after {
  right: calc(-1 * var(--size-gap-2x));
  border-left: none;
  border-right-width: 1px;
}

.Bracket .Tree > div.Right > :after {
  left: calc(-1 * var(--size-gap-2x));
  border-left-width: 1px;
  border-right: none;
}

.Bracket .Tree > div.Left > :before {
  right: calc(-1 * var(--size-gap-2x)  - 8px);
}

.Bracket .Tree > div.Right > :before {
  left: calc(-1 * var(--size-gap-2x)  - 8px);
}

.Bracket .Tree > div.Left > div:has(.IndexGame:first-child:last-child):after, .Bracket .Tree > div.Right > div:has(.IndexGame:first-child:last-child):after {
  display: none;
}

.Bracket .Tree > div.Left > div:has(.IndexGame:first-child:last-child):before {
  right: calc(-1 * var(--size-gap-2x)  - 4px);
}

.Bracket .Tree > div.Right > div:has(.IndexGame:first-child:last-child):before {
  left: calc(-1.5 * var(--size-gap-2x)  + 4px);
}

.Bracket .Tree > div > div {
  justify-content: space-around;
  gap: var(--size-gap-2x);
  flex-direction: column;
  flex-shrink: 0;
  display: flex;
}

.Bracket.Vertical .Tree {
  gap: 0;
  margin: 0;
  padding: 0;
}

.Bracket.Vertical .Tree > div {
  gap: var(--size-gap);
  width: 250px;
}

.Bracket.Vertical .Tree > div.Left > div:before {
  right: 0;
}

.Bracket.Vertical .Tree > div.Left > div:after {
  right: 7px;
}

.Bracket.Vertical .Tree > div > div {
  gap: var(--size-gap);
}

.Button {
  background-image: linear-gradient(70deg, var(--color-brand-primary), var(--color-brand-primary));
  background-color: var(--color-brand-primary);
  padding: var(--size-gap-half);
  color: var(--color-white);
  font-family: var(--font-highlight);
  text-align: center;
  border-radius: 4px;
  display: block;
}

.Button:hover {
  text-decoration: none;
}

.Button:hover:after {
  opacity: 1;
}

.Button:after {
  opacity: 0;
  mix-blend-mode: overlay;
  content: "";
  background-image: linear-gradient(120deg, #0000, #00000080);
  border-radius: 4px;
  transition: all .2s;
  position: absolute;
  inset: 0;
}

.Button > div {
  z-index: 1;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.odds-warning {
  color: #ededed;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: 14px;
}

@media (width >= 768px) {
  .odds-warning {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
}

.PhotoVideo {
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  display: grid;
}

.PhotoVideo img {
  width: 100%;
  display: block;
}

.PhotoVideo .PictureFull {
  z-index: 1;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PhotoVideo .VideoPlaceholder, .PhotoVideo video, .PhotoVideo .PictureFull {
  grid-row-start: 1;
  grid-column-start: 1;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.events-list {
  gap: var(--size-gap-2x);
  padding: var(--size-gap-2x) 0;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  display: grid;
}

@media (width >= 1024px) {
  .events-list {
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
  }
}

.events-list .event {
  align-items: unset;
  background: linear-gradient(#1d1c1c 0%, #161616 100%);
  border-radius: 12px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

@media (width >= 1024px) {
  .events-list .event {
    max-width: unset;
    flex-direction: row;
    align-items: center;
  }
}

.events-list .event.to-be-announced {
  height: 460px;
}

@media (width >= 768px) {
  .events-list .event.to-be-announced {
    height: unset;
    align-items: center;
  }
}

.events-list .event .image-container {
  grid-template-columns: 1fr;
  display: grid;
}

.events-list .event .image-container .number-container {
  z-index: 2;
  flex-direction: row;
  grid-area: 1 / 1;
  display: flex;
}

.events-list .event .image-container .number-container .number {
  background-color: var(--color-otx-blue);
  border-radius: 12px 0 0;
  justify-self: center;
  height: 62px;
  padding: 16px 20px;
  font-size: 20px;
}

.events-list .event .image-container .number-container .number--past {
  background-color: var(--color-gray-80);
}

.events-list .event .image-container .image {
  border-radius: 24px 0 0;
  grid-area: 1 / 1;
  overflow: hidden;
}

@media (width >= 1024px) {
  .events-list .event .image-container .image {
    width: 403px;
  }
}

.events-list .event .info-container {
  justify-content: space-between;
  gap: var(--size-gap);
  padding: var(--size-gap);
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 120px;
  display: flex;
}

@media (width >= 768px) {
  .events-list .event .info-container {
    min-height: unset;
    justify-content: center;
  }
}

.events-list .event .info-container .info {
  gap: var(--size-gap);
  flex-direction: column;
  display: flex;
}

.events-list .event .info-container .info .main-info {
  flex-direction: column;
  gap: 8px;
  display: flex;
}

.events-list .event .info-container .info .main-info .title {
  font-size: 16px;
}

@media (width >= 768px) {
  .events-list .event .info-container .info .main-info .title {
    font-size: 18px;
  }
}

.events-list .event .info-container .info .main-info .main-event h2 {
  font-size: 20px;
}

@media (width >= 768px) {
  .events-list .event .info-container .info .main-info .main-event h2 {
    font-size: 24px;
  }
}

.events-list .event .info-container .info .main-info .tournament-round {
  color: var(--color-blue-25);
  text-transform: uppercase;
  font-size: 14px;
}

.events-list .event .info-container .info .highlights {
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
  display: grid;
}

.events-list .event .info-container .info .highlights .highlight-icon {
  align-items: center;
  display: grid;
}

.events-list .event .info-container .info .highlights .highlight-icon img {
  width: 24px;
}

.events-list .event .info-container .info .highlights .highlight-text--uppercase {
  text-transform: uppercase;
}

.events-list .event .info-container .info .description {
  color: var(--color-gray-10);
  font-size: 14px;
  line-height: 20px;
}

@media (width >= 768px) {
  .events-list .event .info-container .info .description {
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}

.events-list .event .info-container .buttons {
  gap: var(--size-gap);
  flex-direction: row;
  display: flex;
}

.events-list .event .info-container .buttons .cta {
  width: 100%;
}

.events-list .event .info-container .buttons .cta svg {
  padding-right: var(--size-gap-quarter);
}

@media (width >= 768px) {
  .events-list .event .info-container .buttons .cta {
    max-width: 235px;
  }
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.otx-number {
  height: 30px;
}

.otx-number.small {
  height: 24px;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.fight-card {
  background: var(--dark-gradient, linear-gradient(180deg, #1d1c1c 0%, #161616 100%));
  border-radius: 12px;
  flex-direction: column;
  place-items: center;
  display: flex;
  overflow: hidden;
}

.fight-card .fight-title {
  border-bottom: solid 1px var(--color-black);
  text-align: center;
  text-transform: uppercase;
  border-radius: 12px 12px 0 0;
  flex-direction: column;
  order: 1;
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  display: flex;
}

@media (width >= 1024px) {
  .fight-card .fight-title {
    border-right: solid 1px var(--color-black);
    border-left: solid 1px var(--color-black);
    border-radius: 0 0 16px 16px;
    justify-items: center;
    width: fit-content;
    min-width: 220px;
    padding: 16px 24px;
    font-size: 18px;
    position: absolute;
  }
}

.fight-card .fight-title--blue {
  background: var(--color-otx-blue);
}

.fight-card .fight-title--black {
  background-color: var(--color-gray-90);
}

.fight-card .fight-title--otx {
  flex-direction: row;
  justify-content: center;
  gap: 8px;
  display: flex;
}

@media (width >= 1024px) {
  .fight-card .fight-title--otx {
    flex-direction: column;
    gap: 4px;
    min-width: 300px;
    padding: 16px 24px;
  }
}

.fight-card .fight-title .otx-title {
  font-family: Orbitron;
  font-weight: 900;
  line-height: 20px;
}

@media (width >= 1024px) {
  .fight-card .fight-title .otx-title {
    font-size: 20px;
    line-height: 24px;
  }
}

.fight-card .fight-title .date {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

@media (width >= 1024px) {
  .fight-card .fight-title .date {
    font-size: 18px;
  }
}

.fight-card .fight-title .fight-title-bout {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.fight-card .fight-results {
  background-color: var(--color-gray-90);
  order: 2;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
  display: flex;
  position: relative;
}

@media (width >= 1024px) {
  .fight-card .fight-results {
    order: 3;
    padding: 8px 24px;
  }
}

.fight-card .fight-results .indicator {
  text-transform: uppercase;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 24px;
  padding: 8px;
  font-size: 14px;
  display: flex;
}

@media (width >= 1024px) {
  .fight-card .fight-results .indicator {
    width: 160px;
    height: 32px;
    font-size: 16px;
    font-weight: 700;
  }
}

.fight-card .fight-results .win-container {
  flex-direction: row;
  gap: 4px;
  display: flex;
}

@media (width >= 1024px) {
  .fight-card .fight-results .win-container {
    gap: 8px;
  }
}

.fight-card .fight-results .win-indicator {
  background: linear-gradient(#ffc915 .32%, #fff5d3 26.69%, #ffc915 52.81%, #ae8d1d 112.5%);
  box-shadow: 0 4px 10.1px #00000040;
}

.fight-card .fight-results .win-indicator span {
  color: #0000;
  background: linear-gradient(#564a11, #bca325);
  -webkit-background-clip: text;
  background-clip: text;
}

.fight-card .fight-results .money-round {
  background: linear-gradient(#ffc915 .32%, #fff5d3 26.69%, #ffc915 52.81%, #ae8d1d 112.5%);
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  padding: 8px;
  display: flex;
  box-shadow: 0 4px 10.1px #00000040;
}

@media (width >= 1024px) {
  .fight-card .fight-results .money-round {
    width: 32px;
    height: 32px;
  }
}

.fight-card .fight-results .money-round .money-round-icon {
  width: 8px;
  height: 14px;
}

@media (width >= 1024px) {
  .fight-card .fight-results .money-round .money-round-icon {
    width: 16px;
    height: 16px;
  }
}

.fight-card .fight-results .loss-indicator {
  background-color: var(--color-gray-80);
  color: var(--color-gray-90);
  box-shadow: 0 4px 10.1px #00000040;
}

.fight-card .fight-results .decision {
  color: var(--color-gray-30);
  white-space: pre;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media (width >= 1024px) {
  .fight-card .fight-results .decision {
    font-size: 16px;
    line-height: 22px;
  }
}

.fight-card .fightCard-fight-card-container {
  flex-direction: column;
  order: 3;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container {
    order: 2;
    padding: 0 24px;
  }
}

.fight-card .fightCard-fight-card-container .fight-card-content {
  display: flex;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fight-card-content {
    justify-content: space-between;
    width: 100%;
  }
}

.fight-card .fightCard-fight-card-container .fight-card-content .fightCard-fighter-container {
  gap: 20px;
  display: flex;
}

.fight-card .fightCard-fight-card-container .fighter-image-container {
  min-width: 140px;
}

.fight-card .fightCard-fight-card-container .fighter-image-container .profile {
  align-self: flex-end;
  place-items: center;
  gap: 8px;
  display: grid;
}

.fight-card .fightCard-fight-card-container .fighter-image-container .profile .profile-image-container {
  aspect-ratio: 3 / 4;
  grid-area: 1 / 1;
  gap: 20px;
  width: 100%;
  max-width: 175px;
  display: grid;
}

.fight-card .fightCard-fight-card-container .fighter-image-container .profile .profile-image-container--mirror {
  transform: scaleX(-1);
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fighter-image-container .profile .profile-image-container {
    max-width: 220px;
  }
}

.fight-card .fightCard-fight-card-container .fighter-image-container .profile .profile-image-container .profile-image {
  width: 100%;
}

.fight-card .fightCard-fight-card-container .fighter-image-container .profile .profile-image-container .profile-image--loser {
  opacity: .25;
}

.fight-card .fightCard-fight-card-container .fighter-info-container {
  display: none;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fighter-info-container {
    align-items: center;
    width: 150px;
    display: flex;
  }
}

.fight-card .fightCard-fight-card-container .fighter-info-container .name {
  text-align: center;
  width: 150px;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fighter-info-container .name {
    text-align: left;
    display: block;
  }

  .fight-card .fightCard-fight-card-container .fighter-info-container .name--right {
    text-align: right;
  }
}

.fight-card .fightCard-fight-card-container .fighter-info-container .name .nick-name {
  color: var(--color-gray-30);
}

.fight-card .fightCard-fight-card-container .fighter-info-container .name .first-name {
  text-transform: uppercase;
  padding-top: 8px;
  font-size: 14px;
  font-stretch: 115%;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fighter-info-container .name .first-name {
    font-size: 20px;
  }
}

.fight-card .fightCard-fight-card-container .fighter-info-container .name .last-name {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
  font-stretch: 115%;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fighter-info-container .name .last-name {
    font-size: 24px;
  }
}

.fight-card .fightCard-fight-card-container .fighter-info-container .name .name-link {
  white-space: nowrap;
  padding-top: 16px;
  font-size: 16px;
}

.fight-card .fightCard-fight-card-container .fighter-info-container .name .name-link svg {
  padding-left: 8px;
  transition: all .33s;
}

.fight-card .fightCard-fight-card-container .fighter-info-container .name .name-link:hover a {
  opacity: 1;
}

.fight-card .fightCard-fight-card-container .fight-info-container {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.fight-card .fightCard-fight-card-container .fight-info-container .fight-info {
  text-align: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 133px;
  margin-top: 30px;
  font-weight: 400;
  display: flex;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fight-info-container .fight-info {
    width: unset;
    margin-top: 110px;
  }
}

.fight-card .fightCard-fight-card-container .fight-info-container .fight-info .fight-lines {
  flex-direction: row;
  align-items: center;
  gap: 8px;
  display: flex;
}

.fight-card .fightCard-fight-card-container .fight-info-container .fight-info .fight-lines .line {
  background-color: #fff;
  width: 16px;
  height: 1px;
}

.fight-card .fightCard-fight-card-container .fight-info-container .fight-info .fight-state {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 900;
  line-height: 20px;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fight-info-container .fight-info .fight-state {
    font-size: 24px;
    line-height: 32px;
  }
}

.fight-card .fightCard-fight-card-container .fight-info-container .fight-info .fight-weight-rounds {
  flex-direction: column;
  gap: 2px;
  display: flex;
}

.fight-card .fightCard-fight-card-container .fight-info-container .fight-info .fight-weight-rounds .weight-class {
  font-size: 14px;
  line-height: 20px;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fight-info-container .fight-info .fight-weight-rounds .weight-class {
    font-size: 16px;
    line-height: 22px;
  }
}

.fight-card .fightCard-fight-card-container .fight-info-container .fight-info .fight-weight-rounds .number-of-rounds {
  color: var(--color-gray-30);
  font-size: 12px;
  line-height: 18px;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fight-info-container .fight-info .fight-weight-rounds .number-of-rounds {
    font-size: 14px;
    line-height: 20px;
  }
}

.fight-card .fightCard-fight-card-container .fight-info-container .info-buttons-container {
  z-index: 10;
  text-align: center;
  justify-content: center;
  gap: 8px;
  width: 200px;
  display: flex;
  position: absolute;
  top: 70%;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fight-info-container .info-buttons-container {
    top: 75%;
  }
}

.fight-card .fightCard-fight-card-container .fight-info-container .info-buttons-container a {
  border: 1px solid var(--color-blue-25);
  background: var(--color-black);
  border-radius: 50px;
  align-items: center;
  gap: 4px;
  padding: 4px 12px;
  font-size: 14px;
  line-height: 20px;
  display: flex;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fight-info-container .info-buttons-container a {
    gap: 8px;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 22px;
  }
}

.fight-card .fightCard-fight-card-container .fight-info-container .info-buttons-container .youtube-button-link .youtube-button-icon {
  height: 24px;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fight-info-container .info-buttons-container .youtube-button-link .youtube-button-icon {
    height: 32px;
  }
}

.fight-card .fightCard-fight-card-container .fight-info-container .info-buttons-container .bracket-button-link .bracket-button-icon {
  height: 20px;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .fight-info-container .info-buttons-container .bracket-button-link .bracket-button-icon {
    height: 28px;
  }
}

.fight-card .fightCard-fight-card-container .mobile-fighter-info-container {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

@media (width >= 1024px) {
  .fight-card .fightCard-fight-card-container .mobile-fighter-info-container {
    display: none;
  }
}

.fight-card .fightCard-fight-card-container .mobile-fighter-info-container > :first-child {
  border-right: solid 1px var(--color-black);
}

.fight-card .fightCard-fight-card-container .fighter-name {
  background-color: var(--color-gray-90);
  text-align: center;
  width: 100%;
  padding: 14px 0;
  line-height: 22px;
}

.fight-card .fightCard-fight-card-container .fighter-name--tbd {
  color: var(--color-gray-30);
}

.fight-card .cta-container {
  border-top: solid 1px var(--color-black);
  background: var(--color-gray-90);
  order: 4;
  justify-content: center;
  gap: 8px;
  width: 100%;
  padding: 12px 8px;
  display: flex;
}

.fight-card .cta-container a {
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 235px;
  height: 34px;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  display: flex;
}

.fight-card .cta-container a include u.medium-desktop {
  height: 37px;
  font-weight: 800;
  line-height: 28px;
}

.fight-card .draftkings-container {
  border-top: solid 1px var(--color-black);
  background-color: var(--color-gray-90);
  order: 5;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4px 16px;
  display: flex;
}

@media (width >= 1024px) {
  .fight-card .draftkings-container {
    padding: 8px 24px;
  }
}

.fight-card .draftkings-container .odds {
  color: var(--color-blue-25);
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  display: flex;
}

@media (width >= 1024px) {
  .fight-card .draftkings-container .odds {
    width: 150px;
    font-size: 20px;
    line-height: 24px;
  }
}

.fight-card .draftkings-container .draftkings-cta-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

.fight-card .draftkings-container .draftkings-cta-container .draftkings-logo-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
  display: flex;
}

.fight-card .draftkings-container .draftkings-cta-container .vertical-line {
  display: none;
}

@media (width >= 1024px) {
  .fight-card .draftkings-container .draftkings-cta-container .vertical-line {
    display: unset;
    background-color: var(--color-gray-80);
    width: 2px;
    height: 14px;
    margin: 0 16px;
  }
}

.fight-card .draftkings-container .draftkings-cta-container .bet-now {
  display: none;
}

@media (width >= 1024px) {
  .fight-card .draftkings-container .draftkings-cta-container .bet-now {
    color: var(--color-blue-25);
    justify-content: center;
    align-items: center;
    gap: 6px;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
  }
}

.fight-card .draftkings-container .draftkings-cta-container .bet-now svg {
  width: 12px;
}

.fight-card .hide-on-mobile {
  display: none;
}

@media (width >= 1024px) {
  .fight-card .hide-on-mobile {
    display: unset;
  }
}

.fight-card .hide-on-desktop {
  display: unset;
}

@media (width >= 1024px) {
  .fight-card .hide-on-desktop {
    display: none;
  }
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.font-shrinker, .font-shrinker * {
  overflow-wrap: break-word;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.page-heading {
  grid-template-columns: auto 1fr auto;
  align-items: center;
  display: grid;
}

.page-heading--center {
  grid-template-columns: 1fr auto 1fr;
}

.page-heading .heading {
  font-size: 24px;
}

@media (width >= 768px) {
  .page-heading .heading {
    font-size: 36px;
  }
}

.page-heading .heading-lines {
  border-top: 3px solid var(--color-gray-80);
  border-bottom: 3px solid var(--color-gray-80);
  content: "";
  height: 16px;
  margin: 2px 0 2px 24px;
}

.page-heading .heading-lines--left {
  margin: 2px 24px 2px 0;
}

@media (width >= 768px) {
  .page-heading .heading-lines {
    border-top: 4px solid var(--color-gray-80);
    border-bottom: 4px solid var(--color-gray-80);
    height: 20px;
  }
}

.page-heading .link {
  flex-wrap: nowrap;
  padding-left: 24px;
  font-size: 14px;
}

@media (width >= 768px) {
  .page-heading .link {
    font-size: 18px;
  }
}

.page-heading .link svg {
  padding-left: 8px;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.events-page .event-photos {
  border-radius: 12px;
  margin: 24px 0;
}

@media (width >= 768px) {
  .events-page .event-photos {
    margin: 24px 0;
  }
}

.event-photos {
  background: linear-gradient(176deg, #1d1c1c 0%, #000 100%);
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 16px;
  padding: 24px 0;
  display: grid;
}

@media (width >= 768px) {
  .event-photos {
    background: linear-gradient(86deg, #1d1c1c 0%, #000 100%);
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    padding: 0;
  }
}

.event-photos .unlock-lock {
  place-items: center;
  width: 100%;
  display: grid;
}

.event-photos .unlock-lock .photo-placeholder {
  aspect-ratio: 5.47 / 3.08;
  grid-area: 1 / 1;
  width: 100%;
}

.event-photos .unlock-lock .photo-placeholder img {
  width: 100%;
}

.event-photos .unlock-lock .lock-icon {
  -webkit-backdrop-filter: blur(7.5px);
  backdrop-filter: blur(7.5px);
  background-color: #fff3;
  border-radius: 32px;
  grid-area: 1 / 1;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  padding: 8px;
  display: grid;
}

.event-photos .unlock-lock .lock-icon img {
  width: 100%;
  max-width: 30px;
}

.event-photos .cta-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  display: flex;
}

.event-photos .cta-container .title {
  font-size: 20px;
}

.event-photos .cta-container .description {
  color: var(--color-gray-30);
  text-align: center;
  max-width: 420px;
  font-size: 14px;
}

.event-photos .cta-container .buttons {
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 300px;
  padding-top: 24px;
  display: grid;
}

.event-photos .cta-container .buttons .cta {
  background-color: var(--color-white);
  color: var(--color-black);
  transition: all .33s;
}

.event-photos .cta-container .buttons .cta:hover {
  color: var(--color-white);
  background-color: #ffffff80;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.section-heading {
  border-top: 2px solid var(--color-gray-90);
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 24px;
  display: grid;
}

@media (width >= 768px) {
  .section-heading {
    padding-top: 24px;
  }
}

@media (width >= 1200px) {
  .section-heading {
    padding-top: 24px;
  }
}

.section-heading--no-border {
  border-top: none;
}

.section-heading .heading {
  font-size: 14px;
}

@media (width >= 768px) {
  .section-heading .heading {
    font-size: 20px;
  }
}

.section-heading .link {
  flex-wrap: nowrap;
  padding-left: 24px;
  font-size: 16px;
}

@media (width >= 768px) {
  .section-heading .link {
    font-size: 18px;
  }
}

.section-heading .link svg {
  padding-left: 8px;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.Table table {
  border-spacing: 0;
  table-layout: fixed;
  border-width: 0;
  width: 100%;
}

.Table thead tr th {
  background-color: var(--color-black);
  padding: var(--size-gap);
  color: var(--color-white);
  text-align: center;
  text-transform: uppercase;
  width: 60px;
  font-weight: 600;
}

.Table thead tr th:first-child {
  z-index: 1;
  padding: var(--size-gap-half);
  width: 160px;
  position: sticky;
  left: 0;
}

@media (width >= 768px) {
  .Table thead tr th:first-child {
    width: 160px;
  }
}

.Table thead tr th.current {
  background-color: var(--color-brand-secondary);
}

.Table tbody th {
  z-index: 1;
  background-color: var(--color-white);
  padding: var(--size-gap-half);
  text-align: left;
  position: sticky;
  left: 0;
}

.Table tbody th, .Table tbody td {
  font-size: var(--font-small);
}

.Table tbody td {
  background-color: var(--color-lightgray);
  text-align: center;
  min-width: 40px;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.Tabs {
  gap: var(--size-gap-half);
  padding: var(--size-gap-2x) 0 0 0;
  scroll-behavior: smooth;
  scrollbar-width: none;
  flex-flow: row;
  display: flex;
  overflow-x: auto;
}

@media (width >= 768px) {
  .Tabs {
    padding: 46px 0;
  }
}

.Tabs > a {
  padding: 0 8px var(--size-gap-half);
  color: var(--color-gray-30);
  white-space: nowrap;
  border-bottom: 4px solid #000;
}

.Tabs > a.current {
  opacity: 1;
  border-bottom: 4px solid var(--color-otx-blue);
  padding-bottom: var(--size-gap-half);
  color: var(--color-white);
  font-weight: 700;
}

.FlagContainer svg {
  width: 20px;
}

:root {
  --color-brand-primary: #000;
  --color-black: #000;
  --color-black-80: #333;
  --color-white: #fff;
  --color-gray-10: #b6b6b6;
  --color-gray-15: #a3a3a3;
  --color-gray-20: #989898;
  --color-gray-30: #919797;
  --color-gray-40: #878787;
  --color-gray-50: #878787;
  --color-gray-80: #414141;
  --color-gray-90: #212121;
  --color-gray-95: #1a1a1a;
  --color-otx-blue: #003df5;
  --color-blue-25: #9ec1fa;
  --color-blue-45: #5b84e9;
  --font-brand: not-set;
  --font-main: not-set;
  --font-highlight: not-set;
  --font-h1: not-set;
  --font-h2: not-set;
  --font-h3: not-set;
  --font-small: not-set;
  --font-tiny: not-set;
  --size-gap: var(--gap);
  --size-gap-quarter: calc(var(--gap) / 4);
  --size-gap-half: calc(var(--gap) / 2);
  --size-gap-2x: calc(var(--gap) * 2);
  --size-gap-3x: calc(var(--gap) * 3);
  --size-gap-4x: calc(var(--gap) * 4);
  --size-gap-6x: calc(var(--gap) * 6);
  --size-row-gap: .55rem;
  --size-gutter: var(--gutter);
  --size-desktop-width: 1024px;
  --breakpoint-full: 1456px;
  --breakpoint-large: 1200px;
  --breakpoint-medium: 1024px;
  --breakpoint-small: 768px;
  --breakpoint-extra-small: 375px;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+370-3FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+300-301, U+303-304, U+308-309, U+323, U+329, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+100-2AF, U+304, U+308, U+329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  font-stretch: 115%;
  src: url("https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2") format("woff2");
  font-family: Roboto Flex;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-style: normal;
  font-weight: 900;
  src: url("https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2") format("woff2");
  font-family: Orbitron;
  font-display: swap;
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+304, U+308, U+329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.tournament-fight-card {
  background: linear-gradient(#1d1c1c 0%, #161616 100%);
  border-radius: 12px;
  width: 100%;
  min-width: 250px;
  max-width: 250px;
  font-size: 12px;
  overflow: hidden;
}

.tournament-fight-card .header-row {
  background-color: var(--color-gray-90);
  flex-direction: row;
  justify-content: space-between;
  padding: 12px;
  display: flex;
}

.tournament-fight-card .header-row .date {
  text-transform: uppercase;
}

.tournament-fight-card .header-row .link {
  text-align: right;
}

.tournament-fight-card .fighters {
  padding: 12px;
}

.tournament-fight-card .fighters .fighter {
  border-bottom: 1px solid var(--color-black-80);
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 6px;
  padding: 6px;
  display: grid;
}

.tournament-fight-card .fighters .fighter--loser {
  opacity: .65;
}

.tournament-fight-card .fighters .fighter:last-child {
  border-bottom: none;
}

.tournament-fight-card .fighters .fighter .result {
  font-size: 12px;
}

.tournament-fight-card .fighters .fighter .flag {
  margin-bottom: -2px;
}

.tournament-fight-card .fighters .fighter .flag svg {
  width: 16px;
  height: 16px;
}

/*# sourceMappingURL=app.css.map */
