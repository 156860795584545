.PhotoVideo {
	display: grid;
	grid-template-rows: 1fr;
	grid-template-columns: 1fr;

	img {
		display: block;
		width: 100%;
	}

	.PictureFull {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
	}

	.VideoPlaceholder {
		grid-row-start: 1;
		grid-column-start: 1;
	}

	video {
		grid-row-start: 1;
		grid-column-start: 1;
	}

	.PictureFull {
		grid-row-start: 1;
		grid-column-start: 1;
	}
}
