@use '~/src/lib/scss/index' as u;

.events-page {
	.event-photos {
		margin: 24px 0;
		border-radius: 12px;

		@include u.desktop {
			margin: 24px 0;
		}
	}
}

.event-photos {
	display: grid;
	grid-template-columns: 1fr;
	justify-items: center;
	gap: 16px;
	background: linear-gradient(176deg, #1d1c1c 0%, #000 100%);
	padding: 24px 0;

	@include u.desktop {
		grid-template-columns: repeat(2, 1fr);
		gap: 0;
		background: linear-gradient(86deg, #1d1c1c 0%, #000 100%);
		padding: 0;
	}

	> .title {
	}
	.unlock-lock {
		display: grid;
		align-items: center;
		justify-items: center;
		width: 100%;

		.photo-placeholder {
			grid-area: 1 / 1;
			aspect-ratio: 5.47/3.08;
			width: 100%;

			img {
				width: 100%;
			}
		}

		.lock-icon {
			display: grid;
			grid-area: 1 / 1;
			justify-content: center;
			align-items: center;
			backdrop-filter: blur(7.5px);
			border-radius: 32px;
			background-color: rgba(255, 255, 255, 0.2);
			padding: 8px;
			width: 64px;
			height: 64px;

			img {
				width: 100%;
				max-width: 30px;
			}
		}
	}

	.cta-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 16px;

		.title {
			font-size: 20px;
		}
		.description {
			max-width: 420px;
			color: var(--color-gray-30);
			font-size: 14px;
			text-align: center;
		}

		.buttons {
			display: grid;
			grid-template-columns: 1fr;
			padding-top: 24px;
			width: 100%;
			max-width: 300px;

			.cta {
				transition: 0.33s ease all;
				background-color: var(--color-white);
				color: var(--color-black);

				&:hover {
					background-color: rgba(255, 255, 255, 0.5);
					color: var(--color-white);
				}
			}
		}
	}
}
