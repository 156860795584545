// Roboto Flex - 400, 600, 700 - grade 20
// https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght,GRAD@8..144,400,20;8..144,600,20;8..144,700,20&display=swap
// font-family: 'Roboto Flex', sans-serif;

/* cyrillic-ext */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
		U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
		U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
		U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
		U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
		U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
		U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXxhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCVhhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCURhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXRhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
		U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCXBhKEbQ.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
		U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaNTepOXO_NexZs0b5QrzlOHb8wHrF2RY6mZsYh9__OGbt8jbktqc2UpZs0KvCD7BPcSBZvOYmUifRuATW8sPJtUscuCEK6cSr1eUx9XgTnE37Ha_FQQp4Fm0PN1zIk5Dntc_R0wZGzhvmD1YNMYHs2drlAQXOwJRiUJVWhfd-cWW4O85NKCUhhK.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
		U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Roboto Flex - 400, 600, 700 - grade 20 - stretch 115%
// https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wdth,wght,GRAD@8..144,115,400,20;8..144,115,600,20;8..144,115,700,20&display=swap
// font-family: 'Roboto Flex', sans-serif;
/* cyrillic-ext */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
		U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
		U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-style: normal;
	font-weight: 400;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
		U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
		U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
		U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-style: normal;
	font-weight: 600;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
		U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhUiWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhwiWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhsiWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0370-03FF;
}
/* vietnamese */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhciWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304,
		U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhYiWLi2.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
		U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
	font-style: normal;
	font-weight: 700;
	font-stretch: 115%;
	src: url(https://fonts.gstatic.com/s/robotoflex/v9/NaOkcZLOBv5T3oB7Cb4i0xG2bAVi2U5Wc7yZWyEEzvBfH8JUoVt_c_v1HvwJZ3GoHzgCEBt1HRsTftbNFHTjLI5U-kN7Ia1dD-BFnA9CAYG4oYDZNw9ZvE52xXP1iPcIDbA1hgb_dHnhjBaLUdDkNqC3MkIFXOwJVhgiWA.woff2)
		format('woff2');
	font-family: 'Roboto Flex';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
		U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

// Orbitron - 900
// https://fonts.googleapis.com/css2?family=Orbitron:wght@900&display=swap
// font-family: 'Orbitron', sans-serif;

/* latin */
@font-face {
	font-style: normal;
	font-weight: 900;
	src: url(https://fonts.gstatic.com/s/orbitron/v29/yMJMMIlzdpvBhQQL_SC3X9yhF25-T1nysimBoWgz.woff2) format('woff2');
	font-family: 'Orbitron';
	font-display: swap;
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329,
		U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
