@use '~/src/lib/scss/index' as u;

.tournament-fight-card {
	border-radius: 12px;
	background: linear-gradient(180deg, #1d1c1c 0%, #161616 100%);
	width: 100%;
	min-width: 250px;
	max-width: 250px;
	overflow: hidden;
	font-size: 12px;

	.header-row {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		background-color: var(--color-gray-90);
		padding: 12px;

		.date {
			text-transform: uppercase;
		}
		.link {
			text-align: right;
		}
	}

	.fighters {
		padding: 12px;

		.fighter {
			display: grid;
			grid-template-columns: auto 1fr auto;
			align-items: center;
			gap: 6px;
			border-bottom: 1px solid var(--color-black-80);
			padding: 6px;

			&--loser {
				opacity: 65%;
			}

			&:last-child {
				border-bottom: none;
			}

			.result {
				font-size: 12px;
			}

			.flag {
				margin-bottom: -2px;

				svg {
					width: 16px;
					height: 16px;
				}
			}
		}
	}
}
