@use '~/src/lib/scss/index' as u;

.Tabs {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: var(--size-gap-half);
	padding: var(--size-gap-2x) 0 0 0;
	overflow-x: auto;
	scroll-behavior: smooth;
	scrollbar-width: none;

	@include u.desktop {
		padding: 46px 0;
	}

	> a {
		border-bottom: 4px solid #000;
		padding: 0 8px var(--size-gap-half);
		color: var(--color-gray-30);
		white-space: nowrap;

		&.current {
			opacity: 100%;
			border-bottom: 4px solid var(--color-otx-blue);
			padding-bottom: var(--size-gap-half);
			color: var(--color-white);
			font-weight: 700;
		}
	}
}
