@use '~/src/lib/scss/index' as u;

.section-heading {
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	border-top: 2px solid var(--color-gray-90);
	padding-top: 16px;
	padding-bottom: 24px;

	@include u.medium-desktop {
	}

	@include u.desktop {
		padding-top: 24px;
	}

	@include u.large-desktop {
		padding-top: 24px;
	}

	&--no-border {
		border-top: none;
	}

	.heading {
		font-size: 14px;

		@include u.desktop {
			font-size: 20px;
		}
	}

	.link {
		flex-wrap: nowrap;
		padding-left: 24px;
		font-size: 16px;

		@include u.desktop {
			font-size: 18px;
		}
		svg {
			padding-left: 8px;
		}
	}
}
