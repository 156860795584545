@use '~/src/lib/scss/index' as u;

.content-container {
	padding: 0 var(--size-gutter);
	max-width: 1440px;

	@include u.desktop {
		margin-inline: auto;
	}
}
