@use '~/src/lib/scss/index' as u;

.footer-legal {
	background-color: var(--color-otx-blue);

	.content-container {
		display: flex;
		flex-direction: column;
		gap: 12px;
		padding: 32px 40px;
		color: var(--color-white);
		font-weight: 700;
		font-size: 12px;

		@include u.desktop {
			flex-direction: row;
			justify-content: space-between;
		}

		.footer-legal__copyright {
		}

		.footer-legal__links {
			a {
				border-right: 1px solid var(--color-gray-40);
				padding: 0 8px;
				color: var(--color-white);

				&:first-child {
					padding-left: 0;
				}
				&:last-child {
					border-right: none;
					padding-right: 0;
				}
			}
		}
	}
}
