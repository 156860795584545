@use '~/src/lib/scss/index' as u;

.events-list {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	gap: var(--size-gap-2x);
	padding: var(--size-gap-2x) 0;

	@include u.medium-desktop {
		grid-template-columns: repeat(1, 1fr);
		flex-direction: column;
	}

	.event {
		display: flex;
		flex-direction: column;
		align-items: unset;
		border-radius: 12px;
		background: linear-gradient(180deg, #1d1c1c 0%, #161616 100%);
		overflow: hidden;

		@include u.medium-desktop {
			flex-direction: row;
			align-items: center;
			max-width: unset;
		}

		&.to-be-announced {
			height: 460px;

			@include u.desktop {
				align-items: center;
				height: unset;
			}
		}

		.image-container {
			display: grid;
			grid-template-columns: 1fr;

			.number-container {
				display: flex;
				grid-area: 1 / 1;
				flex-direction: row;
				z-index: 2;

				.number {
					justify-self: center;
					border-radius: 12px 0 0 0;
					background-color: var(--color-otx-blue);
					padding: 16px 20px;
					height: 62px;
					font-size: 20px;

					&--past {
						background-color: var(--color-gray-80);
					}
				}
			}

			.image {
				grid-area: 1/1;
				border-radius: 24px 0 0 0;
				overflow: hidden;

				@include u.medium-desktop {
					width: 403px;
				}
			}
		}

		.info-container {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			gap: var(--size-gap);
			padding: var(--size-gap);
			width: 100%;
			height: 100%;
			min-height: 120px;

			@include u.desktop {
				justify-content: center;
				min-height: unset;
			}

			.info {
				display: flex;
				flex-direction: column;
				gap: var(--size-gap);

				.main-info {
					display: flex;
					flex-direction: column;
					gap: 8px;

					.title {
						font-size: 16px;

						@include u.desktop {
							font-size: 18px;
						}
					}

					.main-event {
						h2 {
							font-size: 20px;

							@include u.desktop {
								font-size: 24px;
							}
						}
					}

					.tournament-round {
						color: var(--color-blue-25);
						font-size: 14px;
						text-transform: uppercase;
					}
				}

				.highlights {
					display: grid;
					grid-template-columns: auto 1fr;
					column-gap: 8px;
					row-gap: 8px;
					align-items: center;

					.highlight-icon {
						display: grid;
						align-items: center;

						img {
							width: 24px;
						}
					}

					.highlight-text {
						&--uppercase {
							text-transform: uppercase;
						}
					}
				}

				.description {
					color: var(--color-gray-10);
					font-size: 14px;
					line-height: 20px;

					@include u.desktop {
						@include u.max-lines(3);
					}
				}
			}

			.buttons {
				display: flex;
				flex-direction: row;
				gap: var(--size-gap);

				.cta {
					width: 100%;

					svg {
						padding-right: var(--size-gap-quarter);
					}

					@include u.desktop {
						max-width: 235px;
					}
				}
			}
		}
	}
}
