@use '~/src/lib/scss/index' as u;

.footer-navigation {
	background-color: var(--color-otx-blue);

	.content-container {
		display: grid;
		grid-template-columns: 1fr;
		justify-items: center;
		padding: 0 0 72px;

		@include u.desktop {
			display: flex;
			justify-content: space-between;
			padding: 0;
		}

		.logo-container {
			padding: 72px 64px;

			img {
				justify-self: flex-start;
				box-sizing: content-box;
				padding: 25px 21px;
				max-width: 110px;
			}
		}

		.links {
			display: flex;
			flex-direction: row;

			@include u.desktop {
				padding: 96px 0;
			}

			.column {
				display: flex;
				flex-direction: column;
				padding: 0 24px;

				@include u.desktop {
					padding: 0 42px;
				}

				.column-heading {
					font-weight: 600;
					font-size: 18px;

					@include u.desktop {
						font-size: 24px;
					}
				}

				a {
					padding-top: 16px;
					color: var(--color-white);
					font-size: 14px;

					@include u.desktop {
						font-size: 16px;
					}
				}

				.social-icons {
					display: flex;
					flex-direction: column;

					@include u.desktop {
						flex-direction: row;
						align-items: center;
					}

					svg {
						position: relative;

						@include u.desktop {
							padding: 4px 16px 0 0;
						}

						&.youtube {
							height: 18px;
						}
						&.instagram {
							height: 22px;
						}
						&.tiktok {
							height: 20px;
						}
					}
				}
			}
		}
	}
}
