@use '~/src/lib/_root' as root;

// Style mixins
@mixin center-column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

@mixin max-lines($lines) {
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
}

// @mixin clip-corners($radius: 12px) {
// 	clip-path: polygon($radius 0, 100% 0, 100% calc(100% - $radius), calc(100% - $radius) 100%, 0 100%, 0 $radius);
// }

@mixin rounded {
	border-radius: 16px;
	overflow: hidden;
}

// Breakpoint mixins

@mixin full-desktop {
	@media (min-width: root.$breakpoint-full) {
		@content;
	}
}

@mixin large-desktop {
	@media (min-width: root.$breakpoint-large) {
		@content;
	}
}

@mixin medium-desktop {
	@media (min-width: root.$breakpoint-medium) {
		@content;
	}
}

@mixin desktop {
	@media (min-width: root.$breakpoint-small) {
		@content;
	}
}

@mixin mobile {
	@media (max-width: root.$breakpoint-small) {
		@content;
	}
}

@mixin small-mobile {
	@media (max-width: root.$breakpoint-extra-small) {
		@content;
	}
}
