@use '~/src/lib/scss/index' as u;

// Global variables
:root {
	--gap: 16px;
	--gutter: 16px;

	@include u.desktop {
		--gap: 24px;
		--gutter: 24px;
	}

	@include u.large-desktop {
		--gap: 24px;
		--gutter: 42px;
	}
}

// Breakpoint helpers
@include u.mobile {
	.desktop-only {
		display: none !important;
	}
}

@include u.desktop {
	.mobile-only {
		display: none !important;
	}
}

// CSS Resets and Defaults
html {
	box-sizing: border-box;
	font-size: 100%;
}

*,
*:before,
*.after {
	position: relative;
	box-sizing: inherit;
	margin: 0;
	padding: 0;
}

body {
	margin: 0;
	background-color: var(--color-black);
	padding: 0;
	color: var(--color-white);
	font-stretch: 100%;
	line-height: 1.1;
	font-family: 'Roboto Flex', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
	font-weight: 900;
	font-family: 'Orbitron', sans-serif;
	text-transform: uppercase;
}
h1 {
	font-size: 24px;
	@include u.desktop {
		font-size: 24px;
	}
	@include u.large-desktop {
		font-size: 36px;
	}
}
h2 {
	font-size: 18px;
	@include u.desktop {
		font-size: 24px;
	}
}
h3 {
	font-size: 14px;
	@include u.desktop {
		font-size: 20px;
	}
}
h4 {
	color: var(--color-gray-30);
	font-size: 14px;
}
h5 {
	color: var(--color-white);
	font-weight: 600;
	font-size: 18px;
	@include u.desktop {
		font-size: 24px;
	}
}

p {
	line-height: 1.2;
}

a,
a:active,
a:visited {
	transition: 0.33s ease all;
	cursor: pointer;
	color: var(--color-blue-25);
	text-decoration: none;
}

a:hover {
	opacity: 0.5;
}

.cta {
	display: block;
	border-radius: 8px;
	padding: 8px 16px;
	font-weight: 700;
	font-size: 16px;
	text-align: center;

	&.primary {
		transition: 0.33s ease all;
		border: 1px solid transparent;
		background-color: var(--color-otx-blue);
		color: var(--color-white);

		&:hover {
			opacity: 1;
			border: 1px solid var(--color-white);
		}
	}

	&.secondary {
		transition: 0.33s ease all;
		border: 1px solid var(--color-blue-25);
		background-color: transparent;
		color: var(--color-white);

		&:hover {
			opacity: 1;
			border: 1px solid var(--color-white);
		}
	}

	&.tertiary {
		transition: 0.33s ease all;
		border: 1px solid var(--color-white);
		background-color: var(--color-white);
		color: var(--color-black);

		&:hover {
			opacity: 1;
			border: 1px solid var(--color-gray-30);
		}
	}
}

input {
	font-family: 'Roboto Flex';
}

img {
	display: block;
}
