@use '~/src/lib/scss/index' as u;

.odds-warning {
	color: #ededed;
	font-style: italic;
	font-weight: 300;
	font-size: 14px;
	line-height: 14px;

	@include u.desktop {
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
	}
}
