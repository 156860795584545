.layout-header {
	display: flex;
	position: sticky;
	top: 0;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	z-index: 100;
	background-color: var(--color-black);
}
