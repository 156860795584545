@use '~/src/lib/scss/index' as u;

.Table {
	table {
		border-width: 0;
		border-spacing: 0;
		width: 100%;
		table-layout: fixed;
	}

	thead tr th {
		background-color: var(--color-black);
		padding: var(--size-gap);
		width: 60px;
		color: var(--color-white);
		font-weight: 600;
		text-align: center;
		text-transform: uppercase;
		&:first-child {
			position: sticky;
			left: 0;
			z-index: 1;
			padding: var(--size-gap-half);
			width: 160px;

			@include u.desktop {
				width: 160px;
			}
		}
		&.current {
			background-color: var(--color-brand-secondary);
		}
	}
	tbody {
		th {
			position: sticky;
			left: 0;
			z-index: 1;
			background-color: var(--color-white);
			padding: var(--size-gap-half);
			text-align: left;
		}
		th,
		td {
			font-size: var(--font-small);
		}
		td {
			background-color: var(--color-lightgray);
			min-width: 40px;
			text-align: center;
		}
	}
}
