@use '~/src/lib/scss/index' as u;

.fight-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-items: center;
	border-radius: 12px;
	background: var(--dark-gradient, linear-gradient(180deg, #1d1c1c 0%, #161616 100%));
	overflow: hidden;

	// Fight Title Styles
	.fight-title {
		display: flex;
		flex-direction: column;
		order: 1;
		border-bottom: solid 1px var(--color-black);
		border-radius: 12px 12px 0 0;
		padding: 10px 0;
		width: 100%;
		font-weight: 700;
		font-size: 16px;
		line-height: 22px;
		text-align: center;
		text-transform: uppercase;

		@include u.medium-desktop {
			position: absolute;
			justify-items: center;
			border-right: solid 1px var(--color-black);
			border-left: solid 1px var(--color-black);
			border-radius: 0 0 16px 16px;
			padding: 16px 24px;
			width: fit-content;
			min-width: 220px;
			font-size: 18px;
		}

		&--blue {
			background: var(--color-otx-blue);
		}

		&--black {
			background-color: var(--color-gray-90);
		}

		&--otx {
			display: flex;
			flex-direction: row;
			justify-content: center;
			gap: 8px;

			@include u.medium-desktop {
				flex-direction: column;
				gap: 4px;
				padding: 16px 24px;
				min-width: 300px;
			}
		}

		.otx-title {
			font-weight: 900;
			line-height: 20px;
			font-family: Orbitron;

			@include u.medium-desktop {
				font-size: 20px;
				line-height: 24px;
			}
		}

		.date {
			font-weight: 400;
			font-size: 16;
			line-height: 22px;
			text-transform: capitalize;

			@include u.medium-desktop {
				font-size: 18px;
			}
		}

		.fight-title-bout {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
		}
	}

	// Fight Results Styles
	.fight-results {
		display: flex;
		position: relative;
		justify-content: space-between;
		align-items: center;
		order: 2;
		background-color: var(--color-gray-90);
		padding: 8px;
		width: 100%;

		@include u.medium-desktop {
			order: 3;
			padding: 8px 24px;
		}

		.indicator {
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 16px;
			padding: 8px;
			width: 56px;
			height: 24px;
			font-size: 14px;
			text-transform: uppercase;

			@include u.medium-desktop {
				width: 160px;
				height: 32px;
				font-weight: 700;
				font-size: 16px;
			}
		}

		.win-container {
			display: flex;
			flex-direction: row;
			gap: 4px;

			@include u.medium-desktop {
				gap: 8px;
			}
		}

		.win-indicator {
			box-shadow: 0px 4px 10.1px 0px rgba(0, 0, 0, 0.25);
			background: linear-gradient(180deg, #ffc915 0.32%, #fff5d3 26.69%, #ffc915 52.81%, #ae8d1d 112.5%);

			span {
				background: linear-gradient(180deg, #564a11, #bca325);
				background-clip: text;
				color: transparent;
			}
		}

		.money-round {
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0px 4px 10.1px 0px rgba(0, 0, 0, 0.25);
			border-radius: 16px;
			background: linear-gradient(180deg, #ffc915 0.32%, #fff5d3 26.69%, #ffc915 52.81%, #ae8d1d 112.5%);
			padding: 8px;
			width: 24px;
			height: 24px;

			@include u.medium-desktop {
				width: 32px;
				height: 32px;
			}

			.money-round-icon {
				width: 8px;
				height: 14px;

				@include u.medium-desktop {
					width: 16px;
					height: 16px;
				}
			}
		}

		.loss-indicator {
			box-shadow: 0px 4px 10.1px 0px rgba(0, 0, 0, 0.25);
			background-color: var(--color-gray-80);
			color: var(--color-gray-90);
		}

		.decision {
			display: flex;
			position: absolute;
			left: 50%;
			justify-content: center;
			align-items: center;
			transform: translateX(-50%);
			color: var(--color-gray-30);
			font-size: 14px;
			white-space: pre;

			@include u.medium-desktop {
				font-size: 16px;
				line-height: 22px;
			}
		}
	}

	// Internal Content Styles
	.fightCard-fight-card-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		order: 3;
		width: 100%;

		@include u.medium-desktop {
			order: 2;
			padding: 0 24px;
		}

		.fight-card-content {
			display: flex;

			@include u.medium-desktop {
				justify-content: space-between;
				width: 100%;
			}

			.fightCard-fighter-container {
				display: flex;
				gap: 20px;
			}
		}

		/*** _FighterPhoto STYLES ***/
		.fighter-image-container {
			min-width: 140px;

			.profile {
				display: grid;
				align-items: center;
				justify-items: center;
				align-self: flex-end;
				gap: 8px;

				.profile-image-container {
					display: grid;
					grid-area: 1 / 1;
					gap: 20px;
					aspect-ratio: 3/4;
					width: 100%;
					max-width: 175px;

					&--mirror {
						transform: scaleX(-1);
					}

					@include u.medium-desktop {
						max-width: 220px;
					}

					.profile-image {
						width: 100%;

						&--loser {
							opacity: 25%;
						}
					}
				}
			}
		}

		/*** _FighterNameAndProfile STYLES ***/
		.fighter-info-container {
			display: none;

			@include u.medium-desktop {
				display: flex;
				align-items: center;
				width: 150px;
			}

			.name {
				width: 150px;
				text-align: center;

				@include u.medium-desktop {
					display: block;
					text-align: left;

					&--right {
						text-align: right;
					}
				}

				.nick-name {
					color: var(--color-gray-30);
				}

				.first-name {
					padding-top: 8px;
					font-stretch: 115%;
					font-size: 14px;
					text-transform: uppercase;

					@include u.medium-desktop {
						font-size: 20px;
					}
				}

				.last-name {
					font-weight: 600;
					font-stretch: 115%;
					font-size: 16px;
					text-transform: uppercase;

					@include u.medium-desktop {
						font-size: 24px;
					}
				}

				.name-link {
					padding-top: 16px;
					font-size: 16px;
					white-space: nowrap;

					svg {
						transition: 0.33s ease all;
						padding-left: 8px;
					}

					&:hover {
						a {
							opacity: 1;
						}
					}
				}
			}
		}

		.fight-info-container {
			display: flex;
			flex-direction: column;
			align-items: center;

			.fight-info {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 8px;
				margin-top: 30px;
				width: 133px;
				font-weight: 400;
				text-align: center;

				@include u.medium-desktop {
					margin-top: 110px;
					width: unset;
				}

				.fight-lines {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 8px; // space between the lines and word

					.line {
						background-color: white;
						width: 16px;
						height: 1px;
					}
				}

				.fight-state {
					font-weight: 900; // fight state is the word "vs" or "Final"
					font-size: 16px;
					line-height: 20px;
					text-align: center;
					text-transform: uppercase;

					@include u.medium-desktop {
						font-size: 24px;
						line-height: 32px;
					}
				}

				.fight-weight-rounds {
					display: flex;
					flex-direction: column;
					gap: 2px;

					.weight-class {
						font-size: 14px;
						line-height: 20px;

						@include u.medium-desktop {
							font-size: 16px;
							line-height: 22px;
						}
					}

					.number-of-rounds {
						color: var(--color-gray-30);
						font-size: 12px;
						line-height: 18px;

						@include u.medium-desktop {
							font-size: 14px;
							line-height: 20px;
						}
					}
				}
			}

			.info-buttons-container {
				display: flex;
				position: absolute;
				top: 70%;
				justify-content: center;
				gap: 8px;
				z-index: 10;
				width: 200px;
				text-align: center;

				@include u.medium-desktop {
					top: 75%;
				}

				a {
					display: flex;
					align-items: center;
					gap: 4px;
					border: 1px solid var(--color-blue-25);
					border-radius: 12px;
					border-radius: 50px;
					background: var(--color-black);
					padding: 4px 12px;
					font-size: 14px;
					line-height: 20px;

					@include u.medium-desktop {
						gap: 8px;
						padding: 8px 16px;
						font-size: 16px;
						line-height: 22px;
					}
				}

				.youtube-button-link {
					.youtube-button-icon {
						height: 24px;

						@include u.medium-desktop {
							height: 32px;
						}
					}
				}

				.bracket-button-link {
					.bracket-button-icon {
						height: 20px;

						@include u.medium-desktop {
							height: 28px;
						}
					}
				}
			}
		}

		/*** _FighterNameAndProfileMobile STYLES ***/
		// Container styles
		.mobile-fighter-info-container {
			display: flex;
			justify-content: space-between;
			width: 100%;

			@include u.medium-desktop {
				display: none;
			}

			> :first-child {
				border-right: solid 1px var(--color-black);
			}
		}

		// Component styles
		.fighter-name {
			background-color: var(--color-gray-90);
			padding: 14px 0;
			width: 100%;
			line-height: 22px;
			text-align: center;

			&--tbd {
				color: var(--color-gray-30);
			}
		}
	}

	// CTA Buttons at the bottom of the fight card
	.cta-container {
		display: flex;
		justify-content: center;
		order: 4;
		gap: 8px;
		border-top: solid 1px var(--color-black);
		background: var(--color-gray-90);
		padding: 12px 8px;
		width: 100%;

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 10px;
			padding: 10px;
			width: 100%;
			max-width: 235px;
			height: 34px;
			font-weight: 700;
			font-size: 16px;
			line-height: 22px;

			include u.medium-desktop {
				height: 37px;
				font-weight: 800;
				line-height: 28px;
			}
		}
	}

	// draftkings betting styles
	.draftkings-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		order: 5;
		border-top: solid 1px var(--color-black);
		background-color: var(--color-gray-90);
		padding: 4px 16px;
		width: 100%;

		@include u.medium-desktop {
			padding: 8px 24px;
		}

		.odds {
			display: flex;
			justify-content: center;
			align-items: center;
			color: var(--color-blue-25);
			font-weight: 400;
			font-size: 14px;
			line-height: 14px;

			@include u.medium-desktop {
				width: 150px;
				font-size: 20px;
				line-height: 24px;
			}
		}

		.draftkings-cta-container {
			display: flex;
			justify-content: center;
			align-items: center;

			.draftkings-logo-container {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding-bottom: 8px;
			}

			.vertical-line {
				display: none;

				@include u.medium-desktop {
					display: unset;
					margin: 0 16px;
					background-color: var(--color-gray-80);
					width: 2px;
					height: 14px;
				}
			}

			.bet-now {
				display: none;

				@include u.medium-desktop {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 6px;
					color: var(--color-blue-25);
					font-weight: 400;
					font-size: 18px;
					line-height: 22px;
				}

				svg {
					width: 12px;
				}
			}
		}
	}

	.hide-on-mobile {
		display: none;

		@include u.medium-desktop {
			display: unset;
		}
	}

	.hide-on-desktop {
		display: unset;

		@include u.medium-desktop {
			display: none;
		}
	}
}
