@use '~/src/lib/scss/index' as u;
.Suspended {
	@include u.center-column;
	width: 100%;
}

.Error {
	@include u.center-column;
	flex-direction: row;
	gap: var(--size-gap-half);
	font-size: 20px;
}
