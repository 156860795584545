//This file is automatically generated by the build process
//Edit styles.json then run `npm run build` to update
$color-brand-primary: #000;
$color-black: #000;
$color-black-80: #333;
$color-white: #fff;
$color-gray-10: #b6b6b6;
$color-gray-15: #a3a3a3;
$color-gray-20: #989898;
$color-gray-30: #919797;
$color-gray-40: #878787;
$color-gray-50: #878787;
$color-gray-80: #414141;
$color-gray-90: #212121;
$color-gray-95: #1a1a1a;
$color-otx-blue: #003df5;
$color-blue-25: #9ec1fa;
$color-blue-45: #5b84e9;
$font-brand: not-set;
$font-main: not-set;
$font-highlight: not-set;
$font-h1: not-set;
$font-h2: not-set;
$font-h3: not-set;
$font-small: not-set;
$font-tiny: not-set;
$size-gap: var(--gap);
$size-gap-quarter: calc(var(--gap) / 4);
$size-gap-half: calc(var(--gap) / 2);
$size-gap-2x: calc(var(--gap) * 2);
$size-gap-3x: calc(var(--gap) * 3);
$size-gap-4x: calc(var(--gap) * 4);
$size-gap-6x: calc(var(--gap) * 6);
$size-row-gap: 0.55rem;
$size-gutter: var(--gutter);
$size-desktop-width: 1024px;
$breakpoint-full: 1456px;
$breakpoint-large: 1200px;
$breakpoint-medium: 1024px;
$breakpoint-small: 768px;
$breakpoint-extra-small: 375px;
:root {
--color-brand-primary: #000;
--color-black: #000;
--color-black-80: #333;
--color-white: #fff;
--color-gray-10: #b6b6b6;
--color-gray-15: #a3a3a3;
--color-gray-20: #989898;
--color-gray-30: #919797;
--color-gray-40: #878787;
--color-gray-50: #878787;
--color-gray-80: #414141;
--color-gray-90: #212121;
--color-gray-95: #1a1a1a;
--color-otx-blue: #003df5;
--color-blue-25: #9ec1fa;
--color-blue-45: #5b84e9;
--font-brand: not-set;
--font-main: not-set;
--font-highlight: not-set;
--font-h1: not-set;
--font-h2: not-set;
--font-h3: not-set;
--font-small: not-set;
--font-tiny: not-set;
--size-gap: var(--gap);
--size-gap-quarter: calc(var(--gap) / 4);
--size-gap-half: calc(var(--gap) / 2);
--size-gap-2x: calc(var(--gap) * 2);
--size-gap-3x: calc(var(--gap) * 3);
--size-gap-4x: calc(var(--gap) * 4);
--size-gap-6x: calc(var(--gap) * 6);
--size-row-gap: 0.55rem;
--size-gutter: var(--gutter);
--size-desktop-width: 1024px;
--breakpoint-full: 1456px;
--breakpoint-large: 1200px;
--breakpoint-medium: 1024px;
--breakpoint-small: 768px;
--breakpoint-extra-small: 375px;
}
