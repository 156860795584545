@use '~/src/lib/scss/index' as u;

.header-navigation {
	position: sticky;
	top: 0;
	background-color: var(--color-black);
	width: 100%;
	max-width: 1440px;

	.link {
		color: var(--color-gray-50);
		font-weight: 600;
		font-size: 14px;
		text-transform: uppercase;
	}

	.header-navigation__container {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		z-index: 1;
		background-color: var(--color-black);

		@include u.medium-desktop {
			justify-content: flex-start;
		}

		.logo {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin: 16px var(--size-gutter);
			width: 100%;
			max-width: 55px;

			@include u.medium-desktop {
				margin: 16px 0 16px 32px;
				padding-right: 0 24px;
			}

			@include u.full-desktop {
				margin: 16px 0;
			}

			&:hover {
				opacity: 1;
				filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.8));
			}

			img {
				width: 100%;
			}
		}

		.desktop {
			.link {
				display: none;
				border-right: 1px solid var(--color-blue-25);
				padding: 0 32px;

				@include u.medium-desktop {
					display: unset;
				}

				&:last-child {
					border-right: none;
					padding-right: 0;
				}

				&:hover {
					opacity: 1;
					color: white;
				}
			}
		}

		.toggle {
			padding: 16px var(--size-gutter);
			.menu-icon {
				svg {
					max-height: 18px;
				}
			}

			@include u.medium-desktop {
				display: none;
			}
		}
	}

	.mobile {
		display: flex;
		position: fixed;
		top: -150vh;
		left: 0;
		flex-direction: column;
		z-index: -1;
		backdrop-filter: blur(10px);
		transition: 0.33s ease all;
		background-color: rgba(0, 0, 0, 0.85);
		width: 100vw;
		height: 100vh;

		.link {
			margin: 0 var(--size-gap);
			border-bottom: 1px solid var(--color-blue-25);
			padding: var(--size-gap-2x) 0;

			&:last-child {
				border-bottom: none;
			}
		}
	}

	.mobile--open {
		top: 64px;
	}
}
