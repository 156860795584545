@use '~/src/lib/scss/index' as u;

.page-heading {
	display: grid;
	grid-template-columns: auto 1fr auto;
	align-items: center;

	&--center {
		grid-template-columns: 1fr auto 1fr;
	}
	.heading {
		font-size: 24px;

		@include u.desktop {
			font-size: 36px;
		}
	}

	.heading-lines {
		margin: 2px 0px 2px 24px;
		border-top: 3px solid var(--color-gray-80);
		border-bottom: 3px solid var(--color-gray-80);
		height: 16px;
		content: '';

		&--left {
			margin: 2px 24px 2px 0;
		}

		@include u.desktop {
			border-top: 4px solid var(--color-gray-80);
			border-bottom: 4px solid var(--color-gray-80);
			height: 20px;
		}
	}

	.link {
		flex-wrap: nowrap;
		padding-left: 24px;
		font-size: 14px;

		@include u.desktop {
			font-size: 18px;
		}
		svg {
			padding-left: 8px;
		}
	}
}
