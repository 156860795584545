@use '~/src/lib/scss/index' as u;

.not-found-page {
	@include u.center-column;
	padding: calc(3 * var(--size-gutter));

	> h1 {
		margin: var(--size-gap);
		font-size: 64px;
	}
}
